<script setup lang="ts">
import {db} from "@/firebase";
import {collection, query, where, getDocs, orderBy, Timestamp, getDoc, doc, setDoc} from "firebase/firestore";
import {ref, watch} from "vue";
import {PencilIcon} from "@heroicons/vue/24/outline";
import {useUserStore} from "@/stores/userStore";
import {storeToRefs} from "pinia";
import AddTermsDialog from "@/components/admin/AddTermsDialog.vue";
import {useHomeStore} from "@/stores/homeStore";

const userStore = useUserStore();
const {isAdmin} = storeToRefs(userStore);

const homeStore = useHomeStore();
const {terms} = storeToRefs(homeStore);

const termsOfServices = ref(terms.value.content)

watch(terms, () => {
  termsOfServices.value = terms.value.content
});

const getTermsOfServices = async () => {
  termsOfServices.value = terms.value.content
}

getTermsOfServices();

const isEditTermsDialogOpen = ref(false)
const openEditTermsDialog = () => {
  isEditTermsDialogOpen.value = true
}

const closeEditTermsDialog = () => {
  isEditTermsDialogOpen.value = false
  homeStore.getTerms(true)
}

</script>

<template>
  <div class="w-full h-full py-6 relative">
    <button
        v-if="isAdmin"
        @click="openEditTermsDialog"
        class="flex flex-row items-center text-white justify-center bg-brand-secondary px-6 py-2 rounded-xl absolute top-12 right-12">
      <PencilIcon class="h-4 w-4"/>
      <span class="ml-2">Edit</span>
    </button>
    <div class="w-full h-full flex flex-col items-center justify-center">
      <h1 class="text-4xl font-bold">Terms of Services</h1>
      <div class="w-full mt-4 prose lg:prose-xl text-brand-text" v-html="termsOfServices"></div>
    </div>
    <AddTermsDialog :on-close="closeEditTermsDialog" :terms="termsOfServices" v-if="isEditTermsDialogOpen"/>
  </div>
</template>

<style scoped lang="scss">

</style>
