<script setup lang="ts">
import { SunIcon, MoonIcon, FireIcon, BookOpenIcon, UserCircleIcon } from '@heroicons/vue/24/solid'
import { ref, watch } from "vue";
import LoginComponent from "@/components/LoginComponent.vue";
import { useUserStore } from "@/stores/userStore";
import { storeToRefs } from "pinia";
import ProfileIconComponent from "@/components/ProfileIconComponent.vue";

const isLoggedIn = ref(false)
const userStore = useUserStore();

const { userState, plan } = storeToRefs(userStore)
isLoggedIn.value = !!userState.value

let isPro = ref(false);

if (plan.value && plan.value?.expiry_date) {
  isPro.value = plan.value?.expiry_date.toDate() > new Date();
}

watch(plan, (newVal) => {
  isPro.value = newVal?.expiry_date.toDate() > new Date();
});

watch(userState, (newVal) => {
  isLoggedIn.value = !!newVal
});

const darkMode = ref(false)
const showLoginDialog = ref(false)

const toggleDarkMode = () => {
  darkMode.value = !darkMode.value
  document.body.classList.toggle('dark-theme')
  if (darkMode.value) {
    localStorage.setItem('theme', 'dark')
  } else {
    localStorage.setItem('theme', 'light')
  }
}

if (localStorage.getItem('theme') === 'dark') {
  darkMode.value = true
  document.body.classList.add('dark-theme')
} else {
  darkMode.value = false
  document.body.classList.remove('dark-theme')
}

const onOpenLoginDialog = () => {
  showLoginDialog.value = true
}

const onCloseLoginDialog = () => {
  showLoginDialog.value = false
}
</script>

<template>
  <nav class="flex h-[60px] text-[16px] flex-row justify-between px-4 py-4 bg-gray-900 text-white">
    <div class="flex flex-row items-center space-x-4">
      <router-link to="/" class="font-bold text-xl inline-flex items-left justify-center space-x-1 py-1 px-1 rounded-full transition-colors duration-300 text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-yellow-500">
        <span class="hidden sm:inline">STOCKMARKET.club</span>
        <span class="inline text-xs sm:hidden">Stockmarket.club</span>
      </router-link>

      <router-link to="/courses"
                   :class="{
                      'font-bold bg-gray-700 text-white': $route.path === '/courses',
                      'hover:bg-gray-600': $route.path !== '/courses'
                   }"
                   class="font-bold items-center justify-center px-3 py-1 rounded-full transition-colors duration-300">
        <BookOpenIcon class="h-6 w-6 inline sm:hidden" />
        <span class="hidden sm:inline">Courses</span>
      </router-link>
    </div>
    <div class="flex flex-row items-center space-x-4">
      <router-link to="/pro"
                   class="font-bold hover:bg-gray-700 text-white text-[16px] inline-flex items-center justify-center space-x-1 py-1 px-3 rounded-full transition-colors duration-300">
        <FireIcon class="h-6 w-6" />
        <span class="hidden sm:inline">Pro</span>
      </router-link>
      <button class="hover:bg-gray-700 p-2 rounded-full transition-colors duration-300" @click="toggleDarkMode">
        <SunIcon v-if="darkMode" class="h-6 w-6" />
        <MoonIcon v-else class="h-6 w-6" />
      </button>
      <div v-if="isLoggedIn" class="mr-4">
        <ProfileIconComponent :user="userState" />
      </div>
      <button id="login-button" v-if="!isLoggedIn" @click="onOpenLoginDialog"
        class="font-bold text-white transition-colors duration-300 hover:bg-gray-700 hover:text-white p-2 rounded-full">
        <UserCircleIcon class="h-6 w-6 inline sm:hidden" />
        <span class="hidden sm:inline">Login</span>
      </button>
    </div>
  </nav>
  <div v-if="showLoginDialog">
    <LoginComponent :on-close="onCloseLoginDialog" />
  </div>
</template>

<style scoped>
body.dark-theme {
  background-color: #121212;
  color: #ffffff;
}

body.light-mode {
  background-color: white;
  color: black;
}

nav {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
