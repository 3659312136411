<script setup lang="ts">
import {computed, ref, watch} from "vue";
import LoadingComponent from "@/components/LoadingComponent.vue";

import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {useCoursesStore} from "@/stores/coursesStore";
import {storeToRefs} from "pinia";

const props = defineProps<{
  onClose: () => void | undefined,
  lesson: Record<string, any> | null,
  section: Record<string, any> | null
}>();

const lesson = computed(() => props.lesson)
const section = computed(() => props.section)
const isEdit = ref(lesson.value !== null)

const closeDialog = () => {
  props.onClose?.()
}

const coursesStore = useCoursesStore()

const { loading, course } = storeToRefs(coursesStore)

const title = ref('')
const sectionSlug = ref(null)
const videoUrl = ref('')
const description = ref('')
const type = ref('free')
const order = ref('')

if(lesson.value !== null) {
  title.value = lesson.value?.title ?? ""
  sectionSlug.value = section.value?.slug ?? null
  videoUrl.value = lesson.value?.videoUrl ?? ""
  description.value = lesson.value?.description ?? ""
  type.value = lesson.value?.type ?? "free"
  order.value = lesson.value?.order ?? ""
}

const sections: any[] = [];

watch(course, () => {
  if (course.value) {
    sections.push(...course.value.sections)
  }
})

if (course.value != null) {
  sections.push(...course.value.sections)
}

const saveLesson = async (isDelete = false) => {
  if (title.value === '' || sectionSlug.value === null || videoUrl.value === '') {
    alert('Please fill all fields')
    return
  }
  try {
    let payload: any = {
      title: title.value,
      courseSlug: course.value?.slug,
      sectionSlug: sectionSlug.value,
      videoUrl: videoUrl.value,
      description: description.value,
      type: type.value,
      order: parseInt(order.value || '0')
    }
    if (isEdit.value) {
      payload = {
        ...payload,
        isEdit: isEdit.value,
        slug: lesson.value?.slug
      }
    }
    if(isDelete) {
      const response = confirm('Are you sure you want to delete this?')
      if (!response) {
        return
      }
      payload = {
        ...payload,
        isDelete: isDelete
      }
    }
    const response = await coursesStore.addLesson(payload)
    if (response) {
      closeDialog()
    } else {
      alert('Failed to add course')
    }
  } catch (e) {
    console.error(e);
    alert('Failed to add course')
  }
}

</script>

<template>
  <TransitionRoot as="template" :show="true">
    <Dialog as="div" class="relative z-10" @close="closeDialog">
      <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25"/>
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
            class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
          >
            <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-brand-card p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                  as="h3"
                  class="text-lg font-bold leading-6 text-center text-brand-text"
              >
                {{ isEdit ? 'Edit Lesson' : 'Add Lesson' }}
              </DialogTitle>
              <div class="mt-2 py-6 flex flex-col space-y-6 justify-center items-start">
                <h2
                    class="text-lg font-medium leading-6 text-center text-brand-text"
                >
                  Lesson Info
                </h2>
                <div class="grid grid-cols-1 gap-6 w-full">
                  <label class="block">
                    <span class="text-brand-text">Select Section</span>
                    <select v-model="sectionSlug" class="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                      <option v-for="section of sections" :key="section.slug" :value="section.slug">{{ section.title  }}</option>
                    </select>
                  </label>
                  <label class="block">
                    <span class="text-brand-text">Title</span>
                    <input type="text"
                            v-model="title"
                           class="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                           placeholder="">
                  </label>

                  <label class="block">
                    <span class="text-brand-text">Video URL</span>
                    <input type="text"
                           v-model="videoUrl"
                           class="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                           placeholder="">
                  </label>
                  <label class="block">
                    <span class="text-brand-text">Lesson Type</span>
                    <select v-model="type" class="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                      <option value="free">Free</option>
                      <option value="paid">Paid</option>
                    </select>
                  </label>

                  <label class="block">
                    <span class="text-brand-text">Description</span>
                    <textarea
                        v-model="description"
                        class="mt-1 block w-full min-h-[42px] h-[42px] rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                        rows="3"></textarea>
                  </label>

                  <label class="block">
                    <span class="text-brand-text">Order</span>
                    <input type="text"
                           v-model="order"
                           class="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                           placeholder="">
                  </label>
                </div>
                <LoadingComponent v-if="loading"/>
                <div v-if="!loading" class="flex flex-row space-x-4 justify-center w-full">
                  <button
                      @click="() => saveLesson(false)"
                      class="bg-brand-secondary justify-center w-32 text-center text-white font-bold space-x-2 px-6 py-2 rounded-md flex flex-row">
                    <span>Save</span>
                  </button>
                  <button
                      @click="closeDialog"
                      class="bg-red-500 justify-center w-32 text-center text-white font-bold space-x-2 px-6 py-2 rounded-md flex flex-row">
                    <span>Cancel</span>
                  </button>
                  <button
                      v-if="isEdit"
                      @click="() => saveLesson(true)"
                      class="border-red-500 text-red-500 border-2 justify-center w-32 text-center font-bold space-x-2 px-6 py-2 rounded-md flex flex-row">
                    <span>Delete</span>
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped lang="scss">

</style>
