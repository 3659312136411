<script setup lang="ts">

</script>

<template>
  <div class="flex flex-row w-full justify-center items-center">
    <img alt="Loading" class="w-12 h-12" src="@/assets/loading.svg">
  </div>
</template>

<style scoped lang="scss">

</style>
