<script setup lang="ts">
import {ref, watch} from "vue";
import {PencilIcon} from "@heroicons/vue/24/outline";
import {useUserStore} from "@/stores/userStore";
import {storeToRefs} from "pinia";
import AddPolicyDialog from "@/components/admin/AddPolicyDialog.vue";
import {useHomeStore} from "@/stores/homeStore";

const userStore = useUserStore();
const {isAdmin} = storeToRefs(userStore);

const homeStore = useHomeStore();
const {policy} = storeToRefs(homeStore);

watch(policy, () => {
  privacyPolicy.value = policy.value.content
});

const privacyPolicy = ref(policy.value.content)

const getPrivacyPolicy = async () => {
  privacyPolicy.value = policy.value.content
}

getPrivacyPolicy();

const isEditPrivacyPolicyDialogOpen = ref(false)
const openEditPrivacyPolicyDialog = () => {
  isEditPrivacyPolicyDialogOpen.value = true
}

const closeEditPrivacyPolicyDialog = () => {
  isEditPrivacyPolicyDialogOpen.value = false
  homeStore.getPolicy(true)
}

</script>

<template>
  <div class="w-full h-full py-6 relative">
    <button
        v-if="isAdmin"
        @click="openEditPrivacyPolicyDialog"
        class="flex flex-row items-center text-white justify-center bg-brand-secondary px-6 py-2 rounded-xl absolute top-12 right-12">
      <PencilIcon class="h-4 w-4"/>
      <span class="ml-2">Edit</span>
    </button>
    <div class="w-full h-full flex flex-col items-center justify-center">
      <h1 class="text-4xl font-bold">Privacy Policy</h1>
      <div class="w-full mt-4 prose lg:prose-xl text-brand-text" v-html="privacyPolicy"></div>
    </div>
    <AddPolicyDialog :on-close="closeEditPrivacyPolicyDialog" :policy="privacyPolicy" v-if="isEditPrivacyPolicyDialogOpen"/>
  </div>
</template>

<style scoped lang="scss">

</style>
