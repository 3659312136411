<script setup lang="ts">
import { PencilIcon } from "@heroicons/vue/24/outline";
import { ref } from "vue";
import EditHomePageDialog from "@/components/admin/EditHomePageDialog.vue";
import { useUserStore } from "@/stores/userStore";
import { storeToRefs } from "pinia";
import { useHomeStore } from "@/stores/homeStore";
import { SunIcon, MoonIcon, FireIcon } from '@heroicons/vue/24/solid'


const userStore = useUserStore();
const { isAdmin } = storeToRefs(userStore);

const homeStore = useHomeStore();
const { homePageData } = storeToRefs(homeStore);

const isEditHomePageDialogOpen = ref(false);
const openEditHomePageDialog = () => {
  isEditHomePageDialogOpen.value = true;
};

const closeEditHomePageDialog = () => {
  isEditHomePageDialogOpen.value = false;
  homeStore.getHomePageData(true);
};
</script>

<template>
  <section class="flex flex-col md:flex-row gap-6 w-full px-8 md:px-16 py-12 relative">
    <button
      v-if="isAdmin"
      @click="openEditHomePageDialog"
      class="flex flex-row items-center text-white justify-center bg-brand-secondary px-6 py-2 rounded-xl absolute top-6 right-6 shadow-lg hover:bg-brand-secondary-dark transition">
      <PencilIcon class="h-5 w-5" />
      <span class="ml-2">Edit</span>
    </button>
    <div class="flex flex-col w-full md:w-2/3 justify-center text-center md:text-left space-y-4">
      <h1 class="text-4xl md:text-5xl font-bold leading-tight">Beginner to Pro : Learn the Stock Market </h1>
      <p class="text-lg md:text-xl">{{ homePageData?.description }}</p>
      <div class="inline-block">
  <router-link to="/pro"
             class="font-bold text-5xl bg-gradient-to-r from-pink-500 to-yellow-500 text-white text-[16px] inline-flex items-center justify-center py-1.5 px-5 rounded-full transition-colors duration-300 hover:from-pink-600 hover:to-yellow-600">
  <FireIcon class="h-8 w-8 mr-2" />
    <span class="text-3xl">Pro</span>
  </router-link>
</div>


    </div>
    <div class="flex flex-row w-full md:w-2/3 justify-right items-center">
      <img :src="homePageData?.image" alt="Graph logo" class="w-full object-contain h-[320px] md:h-[520px] " />
    </div>
    <div v-if="isEditHomePageDialogOpen">
      <EditHomePageDialog :on-close="closeEditHomePageDialog" :homeData="homePageData" />
    </div>
  </section>
</template>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

section {
  font-family: 'Inter', sans-serif;
}

button {
  transition: background-color 0.3s, transform 0.3s;
}

button:hover {
  transform: translateY(-2px);
}

img {
  transition: transform 0.3s;
}

img:hover {
  transform: scale(1.05);
}
</style>
