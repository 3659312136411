import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CoursesSection from "@/components/CoursesSection.vue";
import CourseDetailsPage from "@/views/CourseDetailsPage.vue";
import AdminPage from "@/views/admin/AdminPage.vue";
import ProPage from "@/views/ProPage.vue";
import TermsOfServices from "@/views/TermsOfServices.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/course/:slug/:id',
        name: 'course-details',
        component: CourseDetailsPage
    },
    {
        path: '/courses',
        name: 'courses',
        component: CoursesSection
    },
    {
        path: '/pro',
        name: 'pro',
        component: ProPage
    },
    {
        path: '/terms-of-service',
        name: 'terms-of-service',
        component: TermsOfServices
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// add analytics
router.afterEach((to, from) => {
    logEvent(analytics, "pageview", {
        page_path: to.path,
        page_title: to.name
    });
});

export default router
