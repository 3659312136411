<script setup lang="ts">
import {computed, ref} from "vue";
import LoadingComponent from "@/components/LoadingComponent.vue";

import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {useCoursesStore} from "@/stores/coursesStore";
import {storeToRefs} from "pinia";

const props = defineProps<{
  onClose: () => void | undefined,
  course: Record<string, any> | null
}>();

const course = computed(() => props.course)

const isEdit = ref(course.value !== null)

const closeDialog = () => {
  props.onClose?.()
}

const coursesStore = useCoursesStore()

const { loading } = storeToRefs(coursesStore)

const title = ref('')
const description = ref('')
const image = ref(null)

if (props.course !== null) {
  title.value = course.value?.title ?? ""
  description.value = course.value?.description ?? ""
}

const onFileChange = (e: any) => {
  image.value = e.target.files[0]
}

const saveCourse = async (isDelete = false) => {
  if (title.value === '' || description.value === '' || (image.value === null && !isEdit.value)) {
    alert('Please fill all fields')
    return
  }
  try {
    let payload: any = {
      title: title.value,
      description: description.value,
    }
    if (image.value !== null) {
      payload = {
        ...payload,
        image: image.value
      }
    }
    if (isEdit.value) {
      payload = {
        ...payload,
        isEdit: isEdit.value,
        slug: course.value?.slug
      }
    }
    if(isDelete) {
      const response = confirm('Are you sure you want to delete this?')
      if (!response) {
        return
      }
      payload = {
        ...payload,
        isDelete: isDelete
      }
    }
    const response = await coursesStore.addCourse(payload)
    if (response) {
      closeDialog()
    } else {
      alert('Failed to add course')
    }
  } catch (e) {
    console.error(e);
    alert('Failed to add course')
  }
}

</script>

<template>
  <TransitionRoot as="template" :show="true">
    <Dialog as="div" class="relative z-10" @close="closeDialog">
      <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25"/>
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
            class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
          >
            <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-brand-card p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                  as="h3"
                  class="text-lg font-bold leading-6 text-center text-brand-text"
              >
                {{ isEdit ? 'Edit Course' : 'Add Course' }}
              </DialogTitle>
              <div class="mt-2 py-6 flex flex-col space-y-6 justify-center items-start">
                <h2
                    class="text-lg font-medium leading-6 text-center text-brand-text"
                >
                  Course Info
                </h2>
                <div class="grid grid-cols-1 gap-6 w-full">
                  <label class="block">
                    <span class="text-brand-text">Title</span>
                    <input type="text"
                            v-model="title"
                           class="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                           placeholder="">
                  </label>
                  <label class="block">
                    <span class="text-brand-text">Description</span>
                    <textarea
                        v-model="description"
                        class="mt-1 block w-full min-h-[42px] h-[42px] rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                        rows="3"></textarea>
                  </label>
                  <label class="block">
                    <span class="text-brand-text">Image</span>
                    <input type="file"
                            @change="onFileChange"
                           class="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                           placeholder="john@example.com">
                  </label>
                </div>
                <LoadingComponent v-if="loading"/>
                <div v-if="!loading" class="flex flex-row space-x-4 justify-center w-full">
                  <button
                      @click="() => saveCourse(false)"
                      class="bg-brand-secondary justify-center w-32 text-center text-white font-bold space-x-2 px-6 py-2 rounded-md flex flex-row">
                    <span>Save</span>
                  </button>
                  <button
                      @click="closeDialog"
                      class="bg-red-500 justify-center w-32 text-center text-white font-bold space-x-2 px-6 py-2 rounded-md flex flex-row">
                    <span>Cancel</span>
                  </button>
                  <button
                      v-if="isEdit"
                      @click="() => saveCourse(true)"
                      class="border-red-500 text-red-500 border-2 justify-center w-32 text-center font-bold space-x-2 px-6 py-2 rounded-md flex flex-row">
                    <span>Delete</span>
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped lang="scss">

</style>
