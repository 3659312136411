<script setup lang="ts">
/* global grecaptcha */
import {DevicePhoneMobileIcon} from "@heroicons/vue/24/solid";
import {onMounted, ref} from "vue";
import { signInWithPhoneNumber, RecaptchaVerifier, getAuth } from "firebase/auth";
import LoadingComponent from "@/components/LoadingComponent.vue";
import {VueTelInput} from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css'

const loading = ref(false)
const auth = getAuth();

const verifiedRecaptcha = ref(false)

const props = defineProps<{
  onClose: () => void | undefined
}>();

const phone = ref('')
const otp = ref('')
const isOtpSent = ref(false)

const onPhoneChanged = (value: string, phoneObject: any) => {
  if(phoneObject) {
    phone.value = phoneObject.number
  }
}

const sendOTP = () => {
  if(phone.value.length < 10) {
    alert('Please enter a valid phone number')
    return
  }
  loading.value = true
  const appVerifier = window.recaptchaVerifier;
  signInWithPhoneNumber(auth, phone.value, appVerifier)
      .then((confirmationResult) => {
        isOtpSent.value = true
        loading.value = false
        window.confirmationResult = confirmationResult;
      }).catch((error) => {
    loading.value = false
    alert('Failed to send SMS ' + error)

    window.recaptchaVerifier.render().then(function(widgetId: any) {
      grecaptcha.reset(widgetId);
    });
  });
}

const verifyOTP = () => {
  loading.value = true
  window.confirmationResult.confirm(otp.value).then((result: any) => {
    // localStorage.clear();
    setTimeout(() => {
      window.location.reload();
    })
    loading.value = false
  }).catch((error: any) => {
    loading.value = false
    alert('Failed to verify OTP ' + error)
  });
}

onMounted(() => {
  window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
    'size': 'invisible',
    'callback': (response: any) => {
      verifiedRecaptcha.value = true
    }
  });
});

</script>

<template>
  <div class="flex flex-col space-y-2 justify-center">
    <div class="block">
      <span class="text-brand-text">Phone</span>
      <div class="flex flex-row w-full">
        <vue-tel-input
            @input="onPhoneChanged"
            v-model="phone" class="w-full rounded-md" />
<!--        <input type="tel"-->
<!--               :disabled="isOtpSent"-->
<!--               v-model="phone"-->
<!--               class="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"-->
<!--               placeholder="">-->
      </div>
<!--      <span class="text-xs text-brand-text opacity-75">Please enter with country code (+9199490xxxxx)</span>-->
    </div>
    <label v-if="isOtpSent" class="block">
      <span class="text-brand-text">OTP</span>
      <input type="number"
             v-model="otp"
             maxlength="6"
             class="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
             placeholder="">
    </label>
    <div
        id="sign-in-button" class=""></div>
    <LoadingComponent v-if="loading" />
   <div v-if="!loading" class="flex justify-center">
     <button
         v-if="isOtpSent"
         @click="verifyOTP"
         class="bg-brand text-brand-text mt-6 font-bold space-x-2 px-4 py-2 rounded-md flex flex-row">
       <span>Verify OTP</span>
     </button>
     <button
         v-if="!isOtpSent"
         @click="sendOTP"
         class="bg-brand text-brand-text mt-6 font-bold space-x-2 px-4 py-2 rounded-md flex flex-row">
       <DevicePhoneMobileIcon class="h-6 w-6" />
       <span>Sign in with Phone</span>
     </button>
   </div>
    <button @click="onClose" class="text-sm text-brand-text opacity-75 pt-8 text-center">
      Back
    </button>
  </div>
</template>

<style scoped lang="scss">

</style>
