<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="closeDialog">
      <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
            class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
          >
            <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-brand-card p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-center text-brand-text"
              >
                Sign in to your account
              </DialogTitle>
              <div v-if="!isPhoneSignIn" class="mt-2 py-6 flex flex-col justify-center items-center">
                <button
                    @click="singInWithGoogle"
                    class="bg-brand text-brand-text font-bold space-x-2 px-4 py-2 rounded-md flex flex-row">
                  <img src="@/assets/google-login.svg" alt="Google logo" class="h-6 w-6" />
                  <span>Sign in with Google</span>
                </button>
                <button
                    @click="singInWithPhone"
                    class="bg-brand text-brand-text mt-6 font-bold space-x-2 px-4 py-2 rounded-md flex flex-row">
                  <DevicePhoneMobileIcon class="h-6 w-6" />
                  <span>Sign in with Phone</span>
                </button>
                <p class="text-sm text-brand-text opacity-50 pt-8 text-center">
                  By signing in, you agree to terms of service and privacy policy.
                </p>
              </div>
              <div v-if="isPhoneSignIn" class="mt-2 py-6 flex flex-col justify-center items-center">
                <PhoneLoginComponent :on-close="onClosePhoneSignIn" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { DevicePhoneMobileIcon } from '@heroicons/vue/24/solid'
import { signInWithRedirect, GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import { useFirebaseAuth } from 'vuefire'
import PhoneLoginComponent from "@/components/PhoneLoginComponent.vue";

const props = defineProps<{
  onClose: () => void | undefined
}>();

const open = ref(true)

const closeDialog = () => {
  open.value = false
  props.onClose?.()
}


const auth = useFirebaseAuth();
const error = ref<string | null>(null);

const singInWithGoogle = async () => {
  signInWithPopup(auth, new GoogleAuthProvider())
      .then(() => {
        // localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        })
      })
      .catch((reason) => {
    console.error('Failed signinRedirect', reason)
    error.value = reason
    alert('Failed to sign in with Google ' + reason)
  })
}

const isPhoneSignIn = ref(false)

const singInWithPhone = () => {
  isPhoneSignIn.value = true
}

const onClosePhoneSignIn = () => {
  isPhoneSignIn.value = false
}

</script>

<style scoped lang="scss">

</style>

