<script setup lang="ts">
import { defineProps, ref, watch } from 'vue'
import { storeToRefs } from "pinia";
import { useCoursesStore } from "@/stores/coursesStore";
import { PlusIcon, PencilIcon } from "@heroicons/vue/24/outline";
import AddCourseDialog from "@/components/admin/AddCourseDialog.vue";
import { useUserStore } from "@/stores/userStore";

const userStore = useUserStore();
const { isAdmin } = storeToRefs(userStore);

const coursesStore = useCoursesStore()
const { courses } = storeToRefs(coursesStore)

if (courses.value.length === 0) {
  coursesStore.fetchCourses();
}
watch(courses, () => {
  console.log(courses.value)
});

const props = defineProps({
  showTitle: {
    type: Boolean,
    default: false
  }
})

const isAddCourseDialogOpen = ref(false)
const editCourse = ref(null)
const openAddCourseDialog = () => {
  isAddCourseDialogOpen.value = true
}

const openEditCourseDialog = (course: any) => {
  editCourse.value = course
  isAddCourseDialogOpen.value = true
}

const closeAddCourseDialog = () => {
  isAddCourseDialogOpen.value = false
  editCourse.value = null
  coursesStore.fetchCourses(true);
}
</script>


<template>
  <section
      :class="{
    'mt-6': !props.showTitle
      }"
      class="flex flex-col space-y-8 w-full px-6 md:px-12 py-8 mb-52">
    <h1 v-if="props.showTitle"
        class="xl:text-[44px] text-[40px] font-bold text-center pb-6">Courses</h1>
    <div
        v-if="isAdmin" class="flex flex-row justify-end items-end">
      <button
          @click="openAddCourseDialog"
          class="flex flex-row items-center text-white justify-center bg-brand-secondary px-6 py-2 rounded-xl">
        <PlusIcon class="h-4 w-4"/>
        <span class="ml-2">Add Course</span>
      </button>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-center items-start mx-6">
      <div
          v-for="(course) of courses" :key="course.slug"
          class="relative flex flex-col items-center justify-start h-full max-w-[360px] w-full">
        <router-link
            :to="'/course/' + course.slug + '/0'"
            class="hover:cursor-pointer mx-auto w-full flex flex-col space-y-2 bg-brand-card hover:bg-brand-card-hover text-center justify-start shadow-2xl rounded-xl h-full">
          <div class="aspect-video w-full">
            <img :src="course.image"
                 class="w-full h-full object-cover aspect-video rounded-t-xl"
                 alt="course"/>
          </div>
          <div class="flex flex-col space-y-2 px-3 py-5">
            <h2 class="text-[16px] xs:text-[21px] font-extrabold">{{ course.title }}</h2>
            <p class="pb-2 text-s xs:text-[16px] font-medium">{{ course.description }}</p>
          </div>
        </router-link>
        <button
            v-if="isAdmin"
            @click="() => openEditCourseDialog(course)"
            class="absolute top-2 right-2 bg-brand-secondary text-white flex items-center p-2 rounded-full">
          <PencilIcon class="h-4 w-4"/>
        </button>
      </div>
    </div>

    <div v-if="isAddCourseDialogOpen">
      <AddCourseDialog :on-close="closeAddCourseDialog" :course="editCourse"/>
    </div>
  </section>
</template>

<style scoped lang="scss">
.section {
  @apply flex flex-col space-y-8 w-full px-6 md:px-12 py-8 mb-52;
}

h1 {
  @apply xl:text-[44px] text-[40px] font-bold text-center pb-6;
}

button {
  @apply flex flex-row items-center text-white justify-center bg-brand-secondary px-6 py-2 rounded-xl;
}

.grid {
  @apply grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-center items-start;
}

.router-link {
  @apply hover:cursor-pointer mx-auto w-full flex flex-col space-y-2 bg-brand-card hover:bg-brand-card-hover text-center justify-start shadow-2xl rounded-xl h-full;
}

img {
  @apply w-full h-full object-cover aspect-video rounded-t-xl;
}

h2 {
  @apply text-[16px] xs:text-[21px] font-semibold;
}

p {
  @apply pb-2 text-xs xs:text-[16px];
}

button {
  @apply absolute top-2 right-2 bg-brand-secondary text-white flex items-center p-2 rounded-full;
}
</style>
