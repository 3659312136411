import {defineStore} from "pinia";
import axios from "axios";
import {useUserStore} from "@/stores/userStore";
import Api from "@/utils/constants";

export const useCoursesStore = defineStore("courses", {
    state: (): {
        courses: Array<Record<string, any>>;
        loading: boolean;
        course: Record<string, any> | undefined
    } => ({
        courses: [],
        loading: false,
        course: undefined,
    }),
    actions: {
        async fetchCourses(isForce = false) {
            this.loading = true;
            try {
                if (isForce) {
                    localStorage.removeItem("courses");
                    localStorage.removeItem("coursesTimestamp");
                }
                const cache = localStorage.getItem("courses");
                const timestamp = localStorage.getItem("coursesTimestamp");
                if (cache && timestamp) {
                    const currentTime = new Date().getTime();
                    if (currentTime - parseInt(timestamp) < 7200000) {
                        this.courses = JSON.parse(cache);
                        this.loading = false;
                        return;
                    }
                }
                const response = await axios.get(Api.GET_ALL_COURSES);
                this.courses = response.data;
                localStorage.setItem("courses", JSON.stringify(this.courses));
                localStorage.setItem("coursesTimestamp", new Date().getTime().toString());
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        async fetchCourse(courseSlug: string, isForce = false) {
            this.loading = true;
            try {
                // if (isForce) {
                //     localStorage.removeItem(`course-${courseSlug}`);
                //     localStorage.removeItem(`courseTimestamp-${courseSlug}`);
                // }
                // const cache = localStorage.getItem(`course-${courseSlug}`);
                // const timestamp = localStorage.getItem(`courseTimestamp-${courseSlug}`);
                // if (cache && timestamp) {
                //     const currentTime = new Date().getTime();
                //     if (currentTime - parseInt(timestamp) < 600000) {
                //         this.course = JSON.parse(cache);
                //         this.loading = false;
                //         return;
                //     }
                // }
                let headers: Record<string, string> = {}
                const userStore = useUserStore();
                if (userStore.userState) {
                    const uid = userStore.userState.uid;
                    headers = {
                        "authorization": `Bearer ${uid}`
                    }
                }
                const response = await axios.get(Api.GET_LESSONS_BY_COURSE(courseSlug), {headers});
                this.course = response.data;

                localStorage.setItem(`course-${courseSlug}`, JSON.stringify(this.course));
                localStorage.setItem(`courseTimestamp-${courseSlug}`, new Date().getTime().toString());
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        async addCourse({title, description, image, isEdit, slug, isDelete}: {
            title: string,
            description: string,
            image?: string,
            isEdit?: boolean,
            slug?: string,
            isDelete?: boolean
        }) {
            this.loading = true;
            const url = Api.ADD_COURSE;
            const formData = new FormData();
            if (!isEdit) {
                const slug = title.toLowerCase().replace(/\s/g, '-')
                formData.append('slug', slug);
            }
            if (isDelete) {
                formData.append('isDelete', 'true');
            }
            formData.append('title', title);
            formData.append('description', description);
            if (image) {
                formData.append('image', image);
            }
            if (isEdit) {
                formData.append('slug', slug ?? "");
                formData.append('isEdit', 'true');
            }
            try {
                await axios.post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                this.loading = false;
                return true;
            } catch (e) {
                this.loading = false;
                console.error(e);
                return false;
            }
        },
        async addSection({courseSlug, title, order, isEdit, slug, isDelete}: {
            courseSlug: string,
            title: string,
            order: number,
            isEdit?: boolean,
            slug?: string,
            isDelete?: boolean
        }) {
            this.loading = true;
            const url = Api.ADD_SECTION;
            const formData = new FormData();
            if (!isEdit) {
                const slug = title.toLowerCase().replace(/\s/g, '-')
                formData.append('slug', slug);
            }
            if (isDelete) {
                formData.append('isDelete', 'true');
            }
            formData.append('courseId', courseSlug);
            formData.append('title', title);
            formData.append('order', order.toString());
            if (isEdit) {
                formData.append('isEdit', 'true');
                formData.append('slug', slug ?? "");
            }

            try {
                await axios.post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                this.loading = false;
                return true;
            } catch (e) {
                this.loading = false;
                console.error(e);
                return false;
            }
        },
        async addLesson({courseSlug, sectionSlug, title, videoUrl, description, type, order, isEdit, slug, isDelete}: {
            courseSlug: string,
            sectionSlug: string,
            title: string,
            videoUrl: string,
            type: string,
            description: string,
            order: number,
            isEdit?: boolean,
            slug?: string,
            isDelete?: boolean
        }) {
            this.loading = true;
            const url = Api.ADD_LESSON;
            const formData = new FormData();
            if (!isEdit) {
                const slug = title.toLowerCase().replace(/\s/g, '-')
                formData.append('slug', slug);
            }
            formData.append('courseId', courseSlug);
            formData.append('sectionId', sectionSlug);
            formData.append('title', title);
            if (isEdit) {
                formData.append('isEdit', 'true');
                formData.append('slug', slug ?? "");
            }
            if (isDelete) {
                formData.append('isDelete', 'true');
            }
            formData.append('videoUrl', videoUrl);
            formData.append('description', description);
            formData.append('type', type);
            formData.append('order', order.toString());

            try {
                await axios.post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                this.loading = false;
                return true;
            } catch (e) {
                this.loading = false;
                console.error(e);
                return false;
            }
        }
    },
});
