<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useCoursesStore } from "@/stores/coursesStore";
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { PlusIcon, PencilIcon } from "@heroicons/vue/24/outline";
import AddSectionDialog from "@/components/admin/AddSectionDialog.vue";
import AddLessonDialog from "@/components/admin/AddLessonDialog.vue";
import { useUserStore } from "@/stores/userStore";
import LoadingComponent from "@/components/LoadingComponent.vue";
import { SunIcon, MoonIcon, FireIcon } from '@heroicons/vue/24/solid'

const route = useRoute()
const coursesStore = useCoursesStore()
const userStore = useUserStore()

const slug = route.params.slug as string
const index = parseInt(route.params.id as string || "0")

const selectedVideo = ref<Record<string, any> | null>(null)

const { course, courses, loading } = storeToRefs(coursesStore)
const { userState, plan, isAdmin, completedLessons } = storeToRefs(userStore)

let isPro = ref(false);

if (plan.value && plan.value?.expiry_date) {
  isPro.value = plan.value?.expiry_date.toDate() > new Date();
}

watch(plan, (newVal) => {
  loading.value = false;
  isPro.value = newVal?.expiry_date.toDate() > new Date();
});

if (userState) {
  coursesStore.fetchCourse(slug)
}

watch(userState, (newVal) => {
  if (newVal) {
    coursesStore.fetchCourse(slug)
  }
  console.log(userState.value);
});

const setSelectedVideo = (video: Record<string, any>) => {
  selectedVideo.value = video
}

const setVideo = () => {
  if (course?.value === null || course?.value === undefined) return
  let count = 0
  for (let i = 0; i < course?.value?.sections.length; i++) {
    const lessonsLength = course?.value?.sections[i].lessons.length
    count += lessonsLength
    if (count > index) {
      try {
        setSelectedVideo(course?.value?.sections[i].lessons[lessonsLength - (count - index)])
      } catch (e) {
        console.error(e)
      }
      break
    }
  }
}

watch(course, () => {
  setVideo();
})
setVideo();

const lessonCompleted = computed(() => {
  if (!userState.value) return false;
  if (!selectedVideo.value) return false;
  return completedLessons.value.includes(selectedVideo.value?.slug)
})

const onChangeLessonCompleted = (e: any) => {
  if (!userState.value) return;
  if (!selectedVideo.value) return;
  if (e.target.checked) {
    userStore.updateUserLesson(selectedVideo.value?.slug, true)
  } else {
    userStore.updateUserLesson(selectedVideo.value?.slug, false)
  }
}

const isAddSectionDialogOpen = ref(false)
const editSection = ref(null)
const openAddSectionDialog = () => {
  isAddSectionDialogOpen.value = true
}
const openEditSectionDialog = (section: any) => {
  editSection.value = section
  isAddSectionDialogOpen.value = true
}
const closeAddSectionDialog = () => {
  isAddSectionDialogOpen.value = false
  editSection.value = null
  editLesson.value = null
  coursesStore.fetchCourse(course.value?.slug);
}

const isAddLessonDialogOpen = ref(false)
const editLesson = ref(null)
const openAddLessonDialog = () => {
  isAddLessonDialogOpen.value = true
}
const openEditLessonDialog = (lesson: any, section: any) => {
  editLesson.value = lesson
  editSection.value = section
  isAddLessonDialogOpen.value = true
}
const closeAddLessonDialog = () => {
  isAddLessonDialogOpen.value = false
  editSection.value = null
  editLesson.value = null
  coursesStore.fetchCourse(course.value?.slug, true);
}

// Helper to find the index of the selected video within its section
const getSelectedVideoIndex = computed(() => {
  if (!course.value || !selectedVideo.value) return null;
  for (let i = 0; i < course.value.sections.length; i++) {
    const section = course.value.sections[i];
    const lessonIndex = section.lessons.findIndex(lesson => lesson.title === selectedVideo.value.title);
    if (lessonIndex !== -1) {
      return lessonIndex + 1; // Adding 1 to make it 1-based index
    }
  }
  return null;
});
</script>

<template>
  <div v-if="!loading" class="flex flex-col py-4 px-6">
    <div v-if="isAdmin" class="flex flex-row space-x-4 justify-end items-end px-12">
      <button
          @click="openAddSectionDialog"
          class="flex flex-row items-center text-white justify-center bg-brand-secondary px-6 py-2 rounded-xl hover:bg-brand-secondary-dark transition duration-300">
        <PlusIcon class="h-4 w-4"/>
        <span class="ml-2">Add Section</span>
      </button>
      <button
          @click="openAddLessonDialog"
          class="flex flex-row items-center text-white justify-center bg-brand-secondary px-6 py-2 rounded-xl hover:bg-brand-secondary-dark transition duration-300">
        <PlusIcon class="h-4 w-4"/>
        <span class="ml-2">Add Lesson</span>
      </button>
    </div>
<div class="course-details relative flex flex-col-reverse md:flex-row py-1 gap-6">
  <div v-if="course" class="w-full md:w-1/4 md:sticky md:top-6 flex flex-col bg-brand-card rounded-md px-2 py-4 h-auto md:h-[calc(92vh)] overflow-hidden">
        <h1 class="text-[22px] font-bold">{{ course?.title }}</h1>
        <div class="flex flex-1 flex-col space-y-4 h-full pt-6 overflow-y-scroll">
          <div v-for="(section, index) of course.sections" :key="'section-' + index" class="flex flex-col">
            <div class="flex flex-row pb-4 justify-between items-center">
              <h2 class="text-[20px] font-bold">{{ section.title }}</h2>
              <button
                  v-if="isAdmin"
                  @click="() => openEditSectionDialog(section)"
                  class="bg-brand-secondary text-white flex items-center p-2 rounded-full hover:bg-brand-secondary-dark transition duration-300">
                <PencilIcon class="h-4 w-4"/>
              </button>
            </div>
            <div class="flex flex-col space-y-2">
                <div v-for="(lesson, index) of section.lessons" :key="'lesson-' + index"
                    @click="() => setSelectedVideo(lesson)"
                    :class="{
                      'bg-brand-card-hover': selectedVideo?.title === lesson.title,
                  }"
                    class="flex flex-row px-2 space-x-2 justify-between py-2 items-center hover:bg-brand-card-hover rounded-md cursor-pointer transition duration-300">
                  <div class="flex flex-row items-center space-x-2">
                    <div class="flex items-center justify-center w-6 h-6 rounded-full bg-blue-500 text-white text-xs font-bold">
                      {{ index + 1 }}
                    </div>
                    <p class="text-[14px] font-semibold">{{ lesson.title }}</p>
                    <p v-if="lesson.type.toLowerCase() == 'free'" class="text-[12px] font-bold uppercase px-1 py-0.5 rounded-md bg-green-400 bg-opacity-50">{{ lesson.type }}</p>
                  </div>
                  <button
                      v-if="isAdmin"
                      @click="() => openEditLessonDialog(lesson, section)"
                      class="bg-brand-secondary text-white flex items-center p-2 rounded-full hover:bg-brand-secondary-dark transition duration-300">
                    <PencilIcon class="h-4 w-4"/>
                  </button>
                </div>
              </div>

          </div>
        </div>
      </div>
      <div v-if="selectedVideo" class="w-full md:w-3/4 flex flex-col space-y-6">
        <div class="flex flex-row items-center space-x-2">
          <div v-if="getSelectedVideoIndex !== null" class="flex items-center justify-center w-6 h-6 rounded-full bg-blue-500 text-white text-xs font-bold">
            {{ getSelectedVideoIndex }}
          </div>
          <h2 class="text-[22px] font-bold">{{ selectedVideo?.title }}</h2>
        </div>
        <div v-if="!isPro && !selectedVideo.videoUrl && !isAdmin" class="mx-auto w-full px-6 lg:px-8 aspect-video flex items-center justify-center bg-slate-800	">
  <div class="flex items-center justify-center">
  <router-link to="/pro"
             class="font-bold text-5xl bg-gradient-to-r from-pink-500 to-yellow-500 text-white text-[16px] inline-flex items-center justify-center py-1.5 px-5 rounded-full transition-colors duration-300 hover:from-pink-600 hover:to-yellow-600">
  <FireIcon class="h-8 w-8 mr-2" />
    <span class="text-2xl">Pro</span>
  </router-link>
  </div>
</div>

        <div v-if="selectedVideo.videoUrl" class="responsive-iframe-container">
          <iframe
              :src="selectedVideo.videoUrl"
              frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              class="responsive-iframe"></iframe>
        </div>
        <div class="hidden sm:flex shadow bg-brand-card rounded-xl px-6 py-3">
          <div>
            <label class="inline-flex items-center">
              <input
                  class="w-3 h-3 sm:w-4 sm:h-4"
                  @change="onChangeLessonCompleted" type="checkbox" :checked="lessonCompleted">
              <span class="ml-2 text-[12px] font-medium md:text-[15px]">Mark Lesson Completed</span>
            </label>
          </div>
        </div>
        <div class="hidden sm:flex shadow-lg bg-brand-card rounded-xl text-[18px] px-6 py-6">
          <p v-if="selectedVideo?.description" class="font-medium">{{ selectedVideo?.description }}</p>
        </div>
      </div>
    </div>

    <div v-if="isAddSectionDialogOpen">
      <AddSectionDialog :on-close="closeAddSectionDialog" :section="editSection" />
    </div>
    <div v-if="isAddLessonDialogOpen">
      <AddLessonDialog :on-close="closeAddLessonDialog" :lesson="editLesson" :section="editSection" />
    </div>
  </div>
  <LoadingComponent v-if="loading" />
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
}

.text-white {
  color: #ffffff;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.p-2 {
  padding: 0.5rem;
}

.px-2 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.transition {
  transition: all 0.3s ease;
}

.duration-300 {
  transition-duration: 0.3s;
}

.text-white {
  color: #ffffff;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.transition {
  transition: all 0.3s ease;
}

.duration-300 {
  transition-duration: 0.3s;
}


.responsive-iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
