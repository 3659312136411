import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDIEfUyNLG-lI1o-iUwAqhHlToZA5Y7egc",
    authDomain: "coursemochu.firebaseapp.com",
    projectId: "coursemochu",
    storageBucket: "coursemochu.appspot.com",
    messagingSenderId: "837353213510",
    appId: "1:837353213510:web:b24c9d1bff18149ee88447",
    measurementId: "G-05L37YH4DZ"
};

export const firebaseApp = initializeApp(firebaseConfig)

export const db = getFirestore(firebaseApp)
export const analytics = getAnalytics(firebaseApp);
