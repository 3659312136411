import {defineStore} from "pinia";
import {ref} from "vue";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {getCurrentUser} from "vuefire";
import {db} from "../firebase";
import {collection, query, where, getDocs, orderBy, Timestamp, getDoc, doc, setDoc} from "firebase/firestore";

export const useUserStore = defineStore("userStore",
    {
        state: (): { userState: any, plan: Record<string, any> | null, plans: any[], isAdmin: boolean, completedLessons: string[]} => ({
            userState: null,
            plan: null,
            plans: [],
            isAdmin: false,
            completedLessons: []
        }),
        actions: {
            initListener() {
                const currentUser = getCurrentUser();
                const auth = getAuth();
                this.userState = auth.currentUser;

                onAuthStateChanged(getAuth(), (user) => {
                    this.userState = user;
                    this.isAdmin = ["7xf3XFBeQeOHYFdGL0v58IfezsL2", "gaoH04KVKyYNOqd9L15IvVJ7Xj23"].includes(user?.uid ?? "")
                    if (user) {
                        this.fetchActivePlans();
                        this.fetchCompletedLessons();
                    }
                });
                this.fetchPlans();
            },
            async fetchActivePlans() {
                const q = query(collection(db, "payments"), where("user", "==", this.userState.uid), where("expiry_date", ">=", Timestamp.now()), orderBy("purchased_date", "desc"));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    // check if the plan is active
                    // newVal?.expiry_date.toDate() > new Date();
                    if (data.expiry_date.toDate() > new Date()) {
                        data.active = true;
                        this.plan = doc.data();
                    }
                });
            },
            async fetchPlans(isForce = false) {
                if (isForce) {
                    localStorage.removeItem("plans");
                    localStorage.removeItem("plansTimestamp");
                }
                const localData = localStorage.getItem("plans");
                const localTimestamp = localStorage.getItem("plansTimestamp");
                // cache for 1 hour
                if (localData && localTimestamp && new Date().getTime() - parseInt(localTimestamp) < 1000 * 60 * 60) {
                    this.plans = JSON.parse(localData);
                    return;
                }

                const q = query(collection(db, "plans"), orderBy("order", "asc"));
                const querySnapshot = await getDocs(q);

                querySnapshot.forEach((doc) => {
                    this.plans.push(doc.data());
                });
                localStorage.setItem("plans", JSON.stringify(this.plans));
                localStorage.setItem("plansTimestamp", new Date().getTime().toString());
            },
            async fetchCompletedLessons() {
                const userDoc = await getDoc(doc(db, `completed_lessons/${this.userState.uid}`));
                userDoc.data();
                this.completedLessons = userDoc.data()?.lessons || [];
            },
            async updateUserLesson(lesson: string, completed: boolean) {
                const userDoc = doc(db, `completed_lessons/${this.userState.uid}`);
                if(!completed) {
                    this.completedLessons = this.completedLessons.filter((l) => l !== lesson);
                } else {
                    this.completedLessons.push(lesson);
                }
                await setDoc(userDoc, {lessons: this.completedLessons}, {merge: true});
            }
        }
    }
);
