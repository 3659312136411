<script setup lang="ts">
import LoadingComponent from "@/components/LoadingComponent.vue";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {ref} from "vue";
import {doc, setDoc, Timestamp} from "firebase/firestore";
import {db} from "@/firebase";

const props = defineProps<{
  onClose: () => void | undefined,
  terms: string | null
}>();


const terms = ref(props.terms || '')
const closeDialog = () => {
  props.onClose?.()
}

const loading = ref(false)

const localTerms = ref(terms)
const myEditor = ref<any>(null)

const saveTerms = async () => {
  localTerms.value = myEditor.value?.getHTML() || ''
  if (localTerms.value === '') {
    alert('Please fill all fields')
    return
  }
  try {
    loading.value = true
    await setDoc(doc(db, 'terms-policy', 'terms'), {
      content: localTerms.value,
      updated_at: Timestamp.now()
    })
    loading.value = false
    alert('Terms updated successfully')
    closeDialog()
  } catch (e) {
    loading.value = false
    console.error(e);
    alert('Failed to update')
  }
}

</script>

<template>
  <TransitionRoot as="template" :show="true">
    <Dialog as="div" class="relative z-10" @close="closeDialog">
      <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25"/>
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
            class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
          >
            <DialogPanel
                class="w-full min-w-3xl transform overflow-hidden rounded-2xl bg-brand-card p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                  as="h3"
                  class="text-lg font-bold leading-6 text-center text-brand-text"
              >
                {{ 'Edit Terms' }}
              </DialogTitle>
              <div class="mt-2 py-6 flex flex-col space-y-6 justify-center items-start">
                <div class="grid grid-cols-1 gap-6 w-full">
                  <div class="flex flex-col !text-brand-text">
                    <span class="text-brand-text">Title</span>
                    <QuillEditor
                        ref="myEditor"
                        theme="snow"
                       :content="localTerms"
                       placeholder="Enter terms here..."
                       content-type="html"
                       v-model="localTerms"/>
                  </div>

                <LoadingComponent v-if="loading"/>
                <div v-if="!loading" class="flex flex-row space-x-4 justify-center w-full">
                  <button
                      @click="() => saveTerms()"
                      class="bg-brand-secondary justify-center w-32 text-center text-white font-bold space-x-2 px-6 py-2 rounded-md flex flex-row">
                    <span>Save</span>
                  </button>
                  <button
                      @click="closeDialog"
                      class="bg-red-500 justify-center w-32 text-center text-white font-bold space-x-2 px-6 py-2 rounded-md flex flex-row">
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped lang="scss">

</style>
