import {defineStore} from "pinia";
import {ref} from "vue";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {getCurrentUser} from "vuefire";
import {db} from "../firebase";
import {collection, query, where, getDocs, orderBy, Timestamp, getDoc, doc, setDoc} from "firebase/firestore";

export const useHomeStore = defineStore("homeStore",
    {
        state: (): { homePageData: any, terms: any, policy: any } => ({
            homePageData: {},
            policy: {},
            terms: {}
        }),
        actions: {
            async getHomePageData(isForce = false) {
                try {
                    if(!isForce) {
                        const localData = localStorage.getItem('home-page');
                        const localTimestamp = localStorage.getItem('home-page-timestamp');
                        if (localData && localTimestamp && new Date().getTime() - parseInt(localTimestamp) < 1000 * 60 * 60 * 24) {
                            this.homePageData = JSON.parse(localData);
                            return;
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
                const querySnapshot = await getDoc(doc(db, 'homepage', 'data'))
                this.homePageData = querySnapshot.data();
                localStorage.setItem('home-page', JSON.stringify(this.homePageData));
                localStorage.setItem('home-page-timestamp', new Date().getTime().toString());

            },
            async getPolicy(isForce = false) {
               try {
                   if(!isForce) {
                       const localData = localStorage.getItem('policy');
                       const localTimestamp = localStorage.getItem('policy-timestamp');
                       if (localData && localTimestamp && new Date().getTime() - parseInt(localTimestamp) < 1000 * 60 * 60 * 2) {
                           this.policy = JSON.parse(localData);
                           return;
                       }
                   }
               } catch (e) {
                   console.error(e);
               }
                const querySnapshot = await getDoc(doc(db, 'terms-policy', 'policy'))
                this.policy = querySnapshot.data();
                localStorage.setItem('policy', JSON.stringify(this.policy));
                localStorage.setItem('policy-timestamp', new Date().getTime().toString());
            },
            async getTerms(isForce = false) {
                try {
                    if(!isForce) {
                        const localData = localStorage.getItem('terms');
                        const localTimestamp = localStorage.getItem('terms-timestamp');
                        if (localData && localTimestamp && new Date().getTime() - parseInt(localTimestamp) < 1000 * 60 * 60 * 2) {
                            this.terms = JSON.parse(localData);
                            return;
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
                const querySnapshot = await getDoc(doc(db, 'terms-policy', 'terms'))
                this.terms = querySnapshot.data();
                localStorage.setItem('terms', JSON.stringify(this.terms));
                localStorage.setItem('terms-timestamp', new Date().getTime().toString());
            }
        }
    }
);
