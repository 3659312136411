<script setup lang="ts">
import axios from "axios";
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {ref} from "vue";
import Api from "@/utils/constants"

const loading = ref(false)
const title = ref('')
const description = ref('')
const image = ref<any>(null)
const instagram = ref('')
const youtube = ref('')

const props = defineProps<{
  onClose: () => void | undefined,
  homeData: Record<string, any> | null
}>();

const isEdit = ref(props.homeData !== null)

const closeDialog = () => {
  props.onClose?.()
}

if(props.homeData !== null) {
  title.value = props.homeData?.title ?? ""
  description.value = props.homeData?.description ?? ""
  instagram.value = props.homeData?.instagram ?? ""
  youtube.value = props.homeData?.youtube ?? ""
}

const postData = async () => {
  // as multipart formadata
  loading.value = true
  const data = new FormData()
  data.append('title', title.value)
  data.append('description', description.value)
  if(image.value !== null) {
    data.append('image', image.value)
  }
  data.append('instagram', instagram.value)
  data.append('youtube', youtube.value)
  if(isEdit.value) {
    data.append('isEdit', isEdit.value.toString())
  }

  try {
    await axios.post(Api.UPDATE_HOME_PAGE, data)
    alert('Home page data updated')
    closeDialog();
  } catch (e) {
    alert('Failed to update home page data')
    console.error(e)
  } finally {
    loading.value = false
  }
}

const handleImageChange = (e: any) => {
  const target = e.target as HTMLInputElement;
  if (target && target.files) {
    image.value = target.files[0];
  }
}
</script>

<template>
  <TransitionRoot as="template" :show="true">
    <Dialog as="div" class="relative z-10" @close="closeDialog">
      <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25"/>
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
            class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
          >
            <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-brand-card p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-center text-brand-text"
              >
                Edit Home Page Data
              </DialogTitle>
              <div class="mt-2 py-6 flex flex-col justify-center items-center">
                <div class="grid grid-cols-1 gap-6 w-full">
                  <label class="block">
                    <span class="text-brand-text">Title</span>
                    <input
                        v-model="title"
                        type="text"
                        class="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                        placeholder="">
                  </label>
                  <label class="block">
                    <span class="text-brand-text">Description</span>
                    <textarea
                        v-model="description"
                        class="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                        rows="3"></textarea>
                  </label>
                  <label class="block">
                    <span class="text-brand-text">Instagram</span>
                    <input
                        v-model="instagram"
                        type="text"
                        class="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                        placeholder="">
                  </label>
                  <label class="block">
                    <span class="text-brand-text">Youtube</span>
                    <input
                        v-model="youtube"
                        type="text"
                        class="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                        placeholder="">
                  </label>
                  <label class="block">
                    <span class="text-brand-text">Image</span>
                    <input
                        @change="handleImageChange"
                        type="file"
                        accept="image/*"
                        class="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                        placeholder="john@example.com">
                  </label>

                  <div v-if="!loading" class="flex flex-row justify-evenly">
                    <button
                        @click="postData"
                        class="bg-brand-secondary justify-center w-32 text-center text-white font-bold space-x-2 px-6 py-2 rounded-md flex flex-row">
                      <span>Save</span>
                    </button>
                    <button
                        @click="closeDialog"
                        class="bg-red-500 justify-center w-32 text-center text-white font-bold space-x-2 px-6 py-2 rounded-md flex flex-row">
                      <span>Cancel</span>
                    </button>
                  </div>

                  <div v-if="loading" class="flex flex-row justify-evenly">
                    <img class="w-12 h-12" src="@/assets/loading.svg">
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped lang="scss">

</style>
