import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createPinia } from "pinia";
import { VueFire, VueFireAuth } from "vuefire";
import { firebaseApp, analytics } from "./firebase";

import "./styles/tailwind.css";
import {useUserStore} from "@/stores/userStore";
import {useHomeStore} from "@/stores/homeStore";

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);

const userStore = useUserStore();
userStore.initListener();

const homeStore = useHomeStore();
homeStore.getHomePageData();
homeStore.getPolicy();
homeStore.getTerms();

app.use(VueFire, {
    firebaseApp,
    modules: [
        VueFireAuth(),
    ],
})

app.use(router).mount("#app");
