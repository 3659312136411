const Api = {
    UPDATE_HOME_PAGE: 'https://updatehomepage-3bdxh5pnia-uc.a.run.app',
    GET_ALL_COURSES: 'https://getallcourses-3bdxh5pnia-uc.a.run.app',
    GET_LESSONS_BY_COURSE: (courseSlug: string) => (`https://getalllessonsbycourse-3bdxh5pnia-uc.a.run.app?courseId=${courseSlug}`),
    ADD_COURSE: "https://addcourse-3bdxh5pnia-uc.a.run.app",
    ADD_SECTION: "https://addsection-3bdxh5pnia-uc.a.run.app",
    ADD_LESSON: "https://addlesson-3bdxh5pnia-uc.a.run.app",
    CREATE_PAYMENT_ID: "https://createpaymentorderid-3bdxh5pnia-uc.a.run.app"
}

export default Api;
