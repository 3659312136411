<template>
  <div :class="isLightMode ? 'z-[9999] light-mode' : 'z-[9999] dark-mode'">
    <Menu as="div" class="relative block justify-center items-center h-full text-left">
      <div class="flex h-full items-center justify-center flex-col">
        <MenuButton class="inline-flex flex-col w-full h-full justify-center items-center">
          <img v-if="image" :alt="name" class="rounded-full w-8 h-8 object-cover" :src="image">
          <UserCircleIcon v-else class="rounded-full w-8 h-8 text-gray-500"/>
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          :class="[
            'absolute right-0 mt-2 w-56 origin-top-right divide-y rounded-md shadow-lg ring-1 focus:outline-none z-[9999]',
            isLightMode ? 'bg-white divide-gray-200 ring-black/5' : 'bg-brand-card divide-gray-100 ring-black/5'
          ]"
        >
          <div class="px-1 py-1">
            <div>
              <button
                disabled
                class="group flex w-full items-center rounded-md px-2 py-2 text-sm"
              >
                {{ name }}
              </button>
            </div>
          </div>

          <div class="px-1 py-1">
            <MenuItem v-slot="{ active }">
              <button
                @click="logout"
                :class="[
                  active ? (isLightMode ? 'bg-red-500 text-white' : 'bg-red-500 text-white') : '',
                  'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                  isLightMode ? 'text-black' : 'text-white'
                ]"
              >
                Logout
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { UserCircleIcon } from '@heroicons/vue/20/solid'
import { ref, toRefs, defineProps } from 'vue';
import { signOut } from 'firebase/auth'
import { useFirebaseAuth } from 'vuefire';

const auth = useFirebaseAuth()

const name = ref('')
const image = ref('')
const isLightMode = ref(true); // Toggle this to switch between light and dark mode

const props = defineProps<{
  user: any
}>()

let { user } = toRefs(props)

if (user) {
  name.value = user.value.displayName || user.value.email || user.value.phoneNumber
  image.value = user.value.photoURL
}

const logout = async () => {
  await signOut(auth);
  window.location.reload()
}
</script>

<style scoped>
.light-mode {
  background-color: bg-gray-900;
  color: black;
}

.dark-mode {
  background-color: bg-gray-900;
  color: white;
}
</style>
